import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import Logo from "../../Images/logo.png";
import { Link } from 'react-router-dom'

const Menubar = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        id="navbar"
        className="shadow-sm bg-white"
        sticky="top"
      >
        <Container>
          <Navbar.Brand href="/">
            <Image src={Logo} className="img-fluid" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto me-auto"> 
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/services">Services</Nav.Link>
            {/* <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link> */}
            <Nav.Link as={Link} to="/clients">Clients</Nav.Link>
            <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact Us</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link
              className="btn-sm btn-primary text-white p-3 text-center"
              as={Link} to="/contact"
            > Enquire Now
            </Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Menubar;
