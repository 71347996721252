import React from "react";
import { Image } from "react-bootstrap";
import Banner from '../Images/service-img.jpg'

const Services = () => {
  return (
    <div>
      <div id="top-page" className="py-3 py-md-4 bg-primary">
        <div className="container">
          <div className="justify-content-center row">
            <div className="col-md-6">
              <div className="text-center">
                <h1 className="text-white">Services</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="service" className="py-3 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="">
                <h2 className="text-primary mb-4">Services</h2>
                {/* <p className="py-1 py-md-3">
                  Lorem ipsum is a placeholder text commonly used to demonstrate
                  the visual form of a document or a typeface without relying on
                  meaningful content. Lorem ipsum may be used as a placeholder
                  before final copy is available. It is also used to temporarily
                  replace text in a process called greeking, which allows
                  designers to consider the form of a webpage or publication,
                  without the meaning of the text influencing the design.
                </p>
                <p>
                  Lorem ipsum is a placeholder text commonly used to demonstrate
                  the visual form of a document or a typeface without relying on
                  meaningful content. Lorem ipsum may be used as a placeholder
                  before final copy is available. It is also used to temporarily
                  replace text in a process called greeking, which allows
                  designers to consider the form of a webpage or publication,
                  without the meaning of the text influencing the design.
                </p>
                <p>
                  Lorem ipsum is a placeholder text commonly used to demonstrate
                  the visual form of a document or a typeface without relying on
                  meaningful content. Lorem ipsum may be used as a placeholder
                  before final copy is available. It is also used to temporarily
                  replace text in a process called greeking, which allows
                  designers to consider the form of a webpage or publication,
                  without the meaning of the text influencing the design.
                </p> */}
                <div className="">
                  <ul>
                    <li> MEP (Mechanical, Electrical and Plumbing)</li>
                    <li> Specialist in Electrical ( HT & LT)</li>
                    <li> Networking System</li>
                    <li> PA System & Fire Alarm System</li>
                    <li> AMC For Electrical</li>
                    <li> Electrical testing</li>
                    <li> Interior Work</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="align-self-center col-md-4">
              <div className="text-center">
                <Image src={Banner} alt="" className="img-fluid"></Image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
