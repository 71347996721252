import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import AboutBanner from "../Images/slider1/image2.png";

const About = () => {
  return (
    <>
      <div id="top-page" className="py-3 py-md-4 bg-primary">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center">
                <h1 className="text-white">
                  About Us
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="about-content" className="py-3 py-md-5">
        <Container>
          <Row>
            <div className="">
            <h2 className="py-2 text-primary">Company Overview</h2>
                <h3 className="py-2 text-primary">Hello Reader,</h3>
                <h4 className="py-2 text-primary">
                 JSE
                </h4>
            </div>
            <Col md={8}>
              <div className="">
                <p> JSE is a start up venture: aiming to provide
                industry standard installation and service in the electrical
                industry, using the best brands with the highest rating.</p>
                <p>
                JSE is equipped to handle jobs right from
                design, Estimation, Drawing and Specification to Tendering,
                Project Co-ordination and Execution, We have an impressive tie
                up with some of most reliable vendors for material supply. We
                also undertake Liasioning with government Organizations like
                BESCOM/KPTCL and CEIG for activities like preparation of
                Applications for power, Drawing Approvals, Spot inspections,
                Inspection of Sub-stations where ever applicable and final
                servicing of the installation.
                </p>
                <p>
                Our Motto is "Client Satisfaction" and to achieve this
                end we will work in close co-ordination with our clients.
                Providing around the clock Supervision, proper Co-ordination,
                quality audits and adhere to the promised schedule.
                </p>
              </div>
            </Col>
            <Col md={4} className="align-self-center">
              <div className="text-center">
                <Image
                  src={AboutBanner}
                  alt="About Banner"
                  className="img-fluid"
                ></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="pt-3 pb-3 pt-md-5 pb-md-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="bg-light1 shadow p-3 rounded ">
                <h2 className="pb-2 text-center text-primary">Our Vision</h2>
                <div className="text-center text-primary">
                  <i className="fas fa-eye fs-1 pb-2"></i>
                </div>
                <p className="pt-2 text-center">
                To be the region’s most professional and respected commercial electrical contractor/ electrical service provider. We understand that our clients appreciate the focus on preventative & proactive maintenance and the resulting peace of mind that comes from working with us.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="bg-light1 shadow p-3 rounded">
                <h2 className="pb-2 text-center text-primary">Our Mission</h2>
                <div className="text-center text-primary">
                  <i className="fas fa-bullseye fs-1 pb-2"></i>
                </div>
                <p className="pt-2 text-center">
                Our mission is to establish long-term, mutually rewarding relationships with each of our valued clients by consistently surpassing their expectations and gaining their trust through exemplary and dedicated performance by every member<br/> of the JSE.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="pt-3 pb-3 pt-md-5 pb-md-5">
        <Container>
          <Row className="justify-content-center">
            <h2 className="text-center text-primary">Founders</h2>
            <Col md={4} className="align-self-center">
            <div className="text-center">
            <img
                          className="img-fluid"
                          src={Sumit}
                          alt="Sumit Gupta" 
                        /> 

            </div>
            <h4 className="text-center text-primary">Sumit Gupta <br />Founder & CEO </h4>
            <h5 className="text-center"><a href="mailto:sg@yellow-walls.com" className="text-primary text-decoration-none">sg@yellow-walls.com</a></h5>      
            <p>Having a decade experience in all sorts of managing portfolio, he has a vision of making real estate like window shopping with adverse detailing and to pursuit this vision he may do all sort of activities.</p>   
            <p>He is here to delight each and every individual buyer by being easy and simple</p>               
            </Col>
            <Col md={4} className="align-self-center">
            <div className="text-center">
            <img
                          className="img-fluid"
                          src={Sangamesh}
                          alt="Sangamesh Mathapati" 
                        /> 

            </div>
            <h4 className="text-center text-primary">Sangamesh Mathapati <br />Co-Founder & Director</h4>
            <h5 className="text-center"><a href="mailto:sangamesh@yellow-walls.com" className="text-primary text-decoration-none">sangamesh@yellow-walls.com</a></h5>   
            </Col>
            <Col md={4} className="align-self-center">
            <div className="text-center">
            <img
                          className="img-fluid"
                          src={Harish}
                          alt="Harish Ahuja" 
                        /> 

            </div>
            <h4 className="text-center text-primary">Harish Ahuja <br />Co-Founder & Director</h4>
            <h5 className="text-center"><a href="mailto:sg@yellow-walls.com" className="text-primary text-decoration-none">harish@yellow-walls.com</a></h5>   
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};


export default About;
