import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../Images/logo.png";

const Footer = () => {
  return (
    <section id="footer" className=" pt-3 pt-md-5 bg-primary">
      {/* <Container>
        <Row>
          <div className="">
            <Image src={Logo} className="img-fluid" alt="" />
          </div>
          <hr className="text-white" />
          <Col md={3}>
            <h3 className="text-white py-3">Company</h3>
            <ul className="list-unstyled text-white">
              <li>About</li>
              <li>Book Appointment</li>
              <li>Pricing</li>
              <li>Blog</li>
              <li>Testimonials</li>
            </ul>
        </Col>
        
        <Col md={4}>
        <Row className="">
            
            <Col md={8}>
            
                        <div className="pt-2 pb-2 align-items-center">
                        <ul className="list-unstyled text-primary">
                <li>Residential Electrical</li>
                <li>Business Electrical</li>
                <li>Industrial Electrical</li>
                <li>Solar Electrical</li>
                <li>Electrical testing</li>
                
                <li>Electrical testing</li>
            </ul>
                        <h6 className="text-start text-light2">Connect With Us : <a href="" target="_blank"><i className="fab fa-facebook-f text-white ms-2"></i></a> <a href="" target="_blank"><i className="fab fa-twitter   text-white ms-2"></i></a> <a href="" target="_blank"><i className="fab fa-youtube   text-white ms-2"></i></a> <a href="" target="_blank"><i className="fab fa-instagram text-white ms-2"></i></a>
                        </h6>                            
                        </div>
                    </Col>          
                    <hr className="text-white" />          
        </Row>
            
        </Col>
        <Col md={4}>
        <h3 className="text-white py-3 text-start">Services</h3>
          </Col>
          <Col md={6} className="align-text-bottom"></Col>
          <Col md={3}>
            <h3 className="text-white py-3 text-start">Services</h3>
            <ul className="list-unstyled text-white">
              <li>Residential Electrical</li>
              <li>Business Electrical</li>
              <li>Industrial Electrical</li>
              <li>Solar Electrical</li>
              <li>Electrical testing</li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="pt-2 text-center">
              <div className="">
                <h6 className="text-center text-light2">
                  Connect With Us :{" "}
                  <a href="" target="_blank">
                    <i className="fab fa-facebook-f text-white ms-2"></i>
                  </a>{" "}
                  <a href="" target="_blank">
                    <i className="fab fa-twitter   text-white ms-2"></i>
                  </a>{" "}
                  <a href="" target="_blank">
                    <i className="fab fa-youtube   text-white ms-2"></i>
                  </a>{" "}
                  <a href="" target="_blank">
                    <i className="fab fa-instagram text-white ms-2"></i>
                  </a>
                </h6>
              </div>
            </div>
            <hr className="text-white" />
          </Col>
        </Row>
      </Container> */}
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h3 className="text-white py-3">Company</h3>
            <ul className="list-unstyled text-white">
              <li>
                <Link to="/about" className="text-decoration-none text-white">
                  About
                </Link>
              </li>
              {/* <li>
                <Link to="/gallery" className="text-decoration-none text-white">
                  Gallery
                </Link>
              </li> */}
              <li>
                <Link to="/projects" className="text-decoration-none text-white">
                  Projects
                </Link>
              </li>
                <li>
                <Link to="/clients" className="text-decoration-none text-white">
                  Clients
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-decoration-none text-white">
                  Book Appointment
                </Link>
              </li>
              {/* <li>
                <a href="#" className="text-decoration-none text-white">
                  Testimonials
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-8">
                <div className="pt-1 pb-1 align-items-center">
                  <div className="text-white">
                    <h3 className="text-white py-3 text-start">Address</h3>
                    <ul className="list-unstyled">
                      <li>NO.25/1, 2nd Main, 5th Cross, Subramani Layout,</li>
                      <li>Kempegowda Road, Ramamurthy Nagar,</li>
                      <li>Bangalore - 560 016</li>
                    <div className="pt-3">
                    <li><i className="fa-solid fa-envelope"></i><a href="mailto:jselectricalsblr@gmail.com" className="text-decoration-none text-white"> jselectricalsblr@gmail.com</a></li>
                      <li><i className="fa-solid fa-envelope"></i><a href="mailto:jse.communications@jselectricals.in" className="text-decoration-none text-white"> jse.communications@jselectricals.in</a></li>
                      <li><i className="fa-solid fa-envelope"></i><a href="mailto:jamespaul@jselectricals.in" className="text-decoration-none text-white"> jamespaul@jselectricals.in</a></li>
                    </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <h3 className="text-white py-3 text-start">Services</h3>
            <ul className="list-unstyled text-white">
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                MEP
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                Business Electrical
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                Industrial Electrical
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                  Solar Electrical
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                Residential Electrical
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                  Electrical testing
                </Link>
              </li>
              <li>
                <Link to="/services" className="text-decoration-none text-white">
                Interior Work
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row border-top border-white">
          <div className="col-md-12">
            <p className="text-center text-white mb-0 p-2">
              © Copyrights JSE 2022. All Rights Reserved.{" "}
              <a
                href="https://logodesigncompanybangalore.com/"
                target="_blank"
                className="text-decoration-none text-white"
              >
                LDCB
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
