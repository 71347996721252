import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import About from './Components/About';
import Contact from './Components/Contact';
import Clients from './Components/Clients';
import Home from './Components/Home';
import Footer from './Components/Includes/Footer';
import Menubar from './Components/Includes/Menubar';
import Services from './Components/Services';
import Projects from './Components/Projects';

function App() {
  return (
    <>
    <div>
     <Router>        
     <Menubar />
       <Routes>
         <Route path="/" index element={<Home />}></Route>
         <Route path="/about" element={<About />}></Route>
         <Route path="/services" element={<Services />}></Route>
         {/* <Route path="/gallery" element={<Gallery />}></Route> */}
         <Route path="/projects" element={<Projects />}></Route>
         <Route path="/clients" element={<Clients />}></Route>
         <Route path="/contact" element={<Contact />}></Route>
       </Routes>
       <Footer />
     </Router>
   </div>  
  </>
  );
}

export default App;
