import React from "react";
import { Carousel, Image } from "react-bootstrap";
import Image1 from "../../Images/slider1/ban-image-1.png";
import Image2 from "../../Images/slider1/ban-image-2.png";
import Image3 from "../../Images/slider1/ban-image-3.png";
import Image4 from "../../Images/slider1/ban-image-4.png";

const MainSlider = () => {
  return (
    <>
      <Carousel>
        <Carousel.Item>
          <Image className="d-block w-100" src={Image1} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={Image2} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={Image3} alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <Image className="d-block w-100" src={Image4} alt="" />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default MainSlider;