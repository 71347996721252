import React from "react";
import {
  Col,
  Container,
  Row,
  Image,
  ListGroup,
  Carousel,
} from "react-bootstrap";
import MainSlider from "./Includes/Mainslider";
import Trust from "../Images/image1.jpg";
import { Link } from "react-router-dom";
import Professional from "../Images/imag-2.png";
import Testimonials from "./Includes/Testimonials";

const Home = () => {
  return (
    <>
      <MainSlider />
      <div className="welcome py-3 py-md-3">
        <div className="container">
          <div className="justify-content-center row">
            <h2 className="text-primary text-uppercase text-center py-3">
              Welcome to JSE
            </h2>
            <div className="col-md-12">
              <p className="">
                <b> JSE</b> is a start up venture: aiming to provide
                industry standard installation and service in the electrical
                industry, using the best brands with the highest rating.
              </p>
              <p className="">
                <b>JSE</b> is equipped to handle jobs right from
                design, Estimation, Drawing and Specification to Tendering,
                Project Co-ordination and Execution, We have an impressive tie
                up with some of most reliable vendors for material supply. We
                also undertake Liasioning with government Organizations like
                BESCOM/KPTCL and CEIG for activities like preparation of
                Applications for power, Drawing Approvals, Spot inspections,
                Inspection of Sub-stations where ever applicable and final
                servicing of the installation.
              </p>
              <p className="">
                Our Motto is <b>"Client Satisfaction"</b> and to achieve this
                end we will work in close co-ordination with our clients.
                Providing around the clock Supervision, proper Co-ordination,
                quality audits and adhere to the promised schedule.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section id="category" className="py-md-5">
        <Container>
          <Row className="justify-content-center">
          <h3 className="text-primary text-center py-3">We Provide the Best</h3>
            <Col md={3}>
              <div id="icons" className="text-center py-1">
                <span className="icon1 me-1 bg-light1">
                  {" "}
                  <i className="fas fa-bolt text-center text-primary"></i>
                </span>
              </div>

              <h5 className="text-primary text-center py-2">
                Reliable Service
              </h5>
              {/* <p className="py-2 text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
            </Col>
            <Col md={3}>
              <div id="icons" className="text-center py-1">
                <span className="icon1 me-1 bg-light1">
                  <i className="fas fa-wallet text-primary"></i>
                </span>
              </div>
              <h5 className="text-primary text-center py-2">
                Affordable Price
              </h5>
              {/* <p className="py-2 text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
            </Col>
            <Col md={3}>
              <div id="icons" className="text-center py-1">
                <span className="icon1 me-1 bg-light1">
                  <i className="fas fa-people-carry-box text-primary"></i>
                </span>
              </div>
              <h5 className="text-primary text-center py-2">
                Professional Technicians
              </h5>
              {/* <p className="py-2 text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
            </Col>
            <Col md={3}>
              <div id="icons" className="text-center py-1">
                <span className="icon1 me-1 bg-light1">
                  <i className="fas fa-gears text-primary"></i>
                </span>
              </div>
              <h5 className="text-primary text-center py-2">
                Worry-Free Experience
              </h5>
              {/* <p className="py-2 text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section id="trust" className="py-md-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} className="align-self-center">
              <h2 className="text-primary bg-light1">
                Trust Our Electrical Team <br />
                with all of Your Service Needs
              </h2>
              <h4 className="text-danger text-uppercase pt-3">Our Team</h4>
              <p className="py-1">
              JSE is lead by a highly Qualified and experienced team with a highly skilled and trained work Force. Striving to deliver even under pressure. Working around the clock and in the tandem with the other agencies. We have an in house CAD department which can produce all necessary project drawing and also "AS BUILT" drawing project closing Documents.
              </p>
              <h4 className="text-danger text-uppercase">Quality &amp; Safety</h4>
              <p className="py-1">
              Quality &amp; Safety take the first place at the work-site. Since our team have worked with many Multinational companies, we are well aware of all required Safety Norms. All basic Safety Equipments will be provide to our task for their safety and of others working on Site.
              </p>
              <h4 className="text-danger text-uppercase">Value Added Service </h4>
              <p className="py-1">
              Apart from Electrical Installation, JSE can also undertake Small and medium size project involving Networking System, Smoke Alarm system. Public Address System, and Access Control System installation.
              </p>
              <div className="bg-light1 py-md-3">
                <Row>
                  <Col md={4}>
                    <div id="icons" className="py-1">
                      <span className="icon1 me-1 bg-light1">
                        <i className="far fa-clock text-primary"></i>
                      </span>
                    </div>
                    <h5 className="text-primary text-center py-2">
                      On Time Off Service
                    </h5>
                  </Col>
                  <Col md={4}>
                    <div id="icons" className=" py-1">
                      <span className="icon1 me-1 bg-light1">
                        <i className="fas fa-certificate text-primary"></i>
                      </span>
                    </div>
                    <h5 className="text-primary py-2">
                      Certification Professional
                    </h5>
                  </Col>
                  <Col md={4}>
                    <div id="icons" className="py-1">
                      <span className="icon1 me-1 bg-light1">
                        <i className="fas fa-gears text-primary"></i>
                      </span>
                    </div>
                    <h5 className="text-primary py-2">25 Years Experience</h5>
                  </Col>
                  <Col md={4}>
                    <a
                      className="btn-sm btn-primary text-white px-5 py-2 ms-3 text-center text-decoration-none"
                      href="/about"
                    >
                      {" "}
                      Learn More
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="text-center shadow">
                <Image className="d-block w-100 " src={Trust} alt="" />
              </div>
            </Col>
            {/* <Row className="justify-content-center">
              <Col md={6}>
                <h2 className="text-primary bg-light1 text-center mt-4">
                  We are Residential & Commercial Electricians
                </h2>
              </Col>
            </Row>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1">
                  <i className="fas fa-angles-down  bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Electrical Installation
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1 ">
                  <i className="fas fa-lightbulb bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Light Installation
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1 ">
                  <i className="fas fa-power-off bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Electrical Switches
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1 ">
                  <i className="fas fa-plug bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Electrical Wiring
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1 ">
                  <i className="fas fa-screwdriver-wrench bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Electrical Repair
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col>
            <Col md={4}>
              <div id="icons" className="py-1 bg-light1 text-center">
                <span className="icon1 me-1 ">
                  <i className="fas fa-helmet-safety bg-primary text-white"></i>
                </span>
                <h5 className="text-primary text-center py-2">
                  Electrical Safety
                </h5>
              </div>
              <p className="py-2 text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p>
            </Col> */}
          </Row>
        </Container>
      </section>

      <section id="professional" className="py-md-5">
        <Container>
          <Row>
            <Col md={4}>
              <div className="text-center shadow">
                <Image className="d-block w-100 " src={Professional} alt="" />
              </div>
            </Col>
            <Col md={8}>
              <h2 className="text-primary py-3">
                We Professional In All Our Dealings
              </h2>
              {/* <p className="py-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ut
                tellus risus. Vivamus vulputate sem sed orci imperdiet, in
                condimentum risus dapibus.Praesent eros arcu, sagittis convallis
                aliquet non, aliquet ut risus. Sed sed varius eros, id pulvinar
                elit. Fusce non elit nec elit ultricies sagittis sed ac augue.
                Pellentesque eu lacinia magna.{" "}
              </p> */}
              <ListGroup variant="flush">
                <ListGroup.Item className="border-bottom border-danger">
                MEP (Mechanical, Electrical and Plumbing)
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                Specialist in Electrical ( HT & LT)
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                Networking System
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                PA System & Fire Alarm System
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                AMC For Electrical
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                Electrical testing
                </ListGroup.Item>
                <ListGroup.Item className="border-bottom border-danger">
                Interior Work
                </ListGroup.Item>
              </ListGroup>
              <Col md={4} className="py-3">
                <a
                  className="btn-sm btn-primary text-white p-3 text-center py-2 ps-2 text-decoration-none"
                  href="/services"
                >
                  {" "}
                  Learn More
                </a>
              </Col>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="need" className="py-md-1 bg-primary my-4">
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="text-white py-3">
              Need to find a reliable Team to fix your MEP and Interior for commercial, Industrial and residential.
              </h3>
            </Col>
            <Col md={2} className="py-5">
              <a
                className="btn-sm btn-light2 text-white text-center px-5 py-2 text-decoration-none"
                href="/contact"
              >
                {" "}
                Contact Us
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section id="testimonial" className="py-md-5 bg-light">
        <Container>
          <div className="bg-light1">
            <h2 className="text-primary bg-light1 text-center">
              What People say about us
            </h2>
          </div>
          <Row>
            <Col md={12}>
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section> */}
    </>
  );
};

export default Home;
