import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Flipkart from "../Images/client/imgg-1.png";
import Snowman from "../Images/client/imgg-2.png";
import Ranger from "../Images/client/imgg-3.png";
import Dhruva from "../Images/client/imgg-4.png";
import MRI from "../Images/client/imgg-14.png";
import EPC from "../Images/client/imgg-5.png";
import PNB from "../Images/client/imgg-6.png";
import Medwell from "../Images/client/imgg-7.png";
import Ipeer from "../Images/client/imgg-8.png";
import Schneider from "../Images/client/imgg-9.png";
import Materials from "../Images/client/imgg-10.png";
import Turning from "../Images/client/imgg-11.png";
import Airvana from "../Images/client/imgg-12.png";
import Electro from "../Images/client/img-13.png";
import Parul from "../Images/client/parull.png";
import Image1 from "../Images/HCL-ITPL-BLR/img-1.JPG";
import Image2 from "../Images/HCL-ITPL-BLR/img-2.JPG";
import Image3 from "../Images/HCL-ITPL-BLR/img-3.JPG";
import Image4 from "../Images/HCL-ITPL-BLR/img-4.JPG";
import Image5 from "../Images/HCL-ITPL-BLR/img-5.JPG";
import Image6 from "../Images/HCL-ITPL-BLR/img-6.JPG";
import Image7 from "../Images/HCL-ITPL-BLR/img-7.JPG";
import Image8 from "../Images/HCL-ITPL-BLR/img-8.JPG";
import Image11 from "../Images/HCL-Jigani/img-11.png";
import Image12 from "../Images/HCL-Jigani/img-12.png";
import Image13 from "../Images/HCL-Jigani/img-13.png";
import Image21 from "../Images/ITPL-Aviator-7F/img-21.JPG";
import Image22 from "../Images/ITPL-Aviator-7F/img-22.JPG";
import Image23 from "../Images/ITPL-Aviator-7F/img-23.JPG";
import Image24 from "../Images/ITPL-Aviator-7F/img-24.JPG";
import Image25 from "../Images/ITPL-Aviator-7F/img-25.JPG";
import Image26 from "../Images/ITPL-Aviator-7F/img-26.JPG";
import Image27 from "../Images/ITPL-Aviator-7F/img-27.JPG";
import Image28 from "../Images/ITPL-Aviator-7F/img-28.JPG";
import Image29 from "../Images/ITPL-Aviator-7F/img-29.JPG";
import Image30 from "../Images/ITPL-Aviator-7F/img-30.JPG";
import Image31 from "../Images/ITPL-Aviator-7F/img-31.JPG";
import Image32 from "../Images/ITPL-Aviator-7F/img-32.JPG";
import Image33 from "../Images/ITPL-Aviator-7F/img-33.JPG";
import Image34 from "../Images/ITPL-Aviator-7F/img-34.JPG";
import Image35 from "../Images/ITPL-Aviator-7F/img-35.JPG";
import Image41 from "../Images/Schneider-Jigani/img-41.JPG";
import Image42 from "../Images/Schneider-Jigani/img-42.JPG";
import Image43 from "../Images/Schneider-Jigani/img-43.JPG";
import Image44 from "../Images/Schneider-Jigani/img-44.JPG";
import Image45 from "../Images/Schneider-Jigani/img-45.JPG";
import Image46 from "../Images/Schneider-Jigani/img-46.JPG";
import Image47 from "../Images/Schneider-Jigani/img-47.JPG";
import Image48 from "../Images/Schneider-Jigani/img-48.JPG";
import Image51 from "../Images/VijaylakshmiSilks/img-51.png";
import Image52 from "../Images/VijaylakshmiSilks/img-52.png";

const Projects = () => {
  return (
    <div>
      <div id="top-page" className="py-3 py-md-4 bg-primary">
        <div className="Container">
          <div className="justify-content-center row">
            <div className="col-md-6">
              <div className="text-center">
                <h1 className="text-white">Projects</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div id="project-list">
        <div className="container">
          <div className="py-md-5">
            <div className="row">
              <h2 className="text-center text-primary text-uppercase py-3">
                Completed Projects
              </h2>
              <div className="col-md-12">
                <div className="py-md-3">
                  <table className="table-bordered table">
                    <thead className="text-center bordered text-uppercase bg-primary text-white">
                      <th colspan="2">List Of Clients</th>
                    </thead>
                    <thead className="text-uppercase">
                      <th>SL.NO</th>
                      <th>Description</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>M/s Flipkart India PVT LTD,Bangalore &emsp;&emsp;&emsp;
                        <Image src={Flipkart} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>M/S. Snowman Logistics Limited, Bangalore &emsp;&emsp;&emsp;
                        <Image src={Snowman} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>M/s Ranger Apparel Export Pvt Ltd., Bangalore &emsp;&emsp;&emsp;
                        <Image src={Ranger} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>M/S Dhruva Infotech Pvt Ltd. &emsp;&emsp;&emsp;
                        <Image src={Dhruva} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>M/S Guru Das Builders Pvt Ltd</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>
                          Mr. Sankalp Saxena(President, Aviation Operations
                          Services(AOS) at IBS Software)
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>M/s Maheshwari Rocks India Pvt Ltd., Bangalore &emsp;&emsp;&emsp;
                        <Image src={MRI} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>
                          M/s Eternal Power &amp; Cooling Services, Bangalore &emsp;&emsp;&emsp;
                          <Image src={EPC} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>M/s Punjab National Bank, Bangalore &emsp;&emsp;&emsp;
                        <Image src={PNB} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>M/S. Medwell Ventures Pvt Ltd. &emsp;&emsp;&emsp;
                        <Image src={Medwell} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>M/s Ipeer India Pvt Ltd, Bangalore &emsp;&emsp;&emsp;
                        <Image src={Ipeer} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>
                          M/S Schneider Electric IT Business India PVT LTD,
                          Bangalore. &emsp;&emsp;&emsp;
                          <Image src={Schneider} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>M/s. Applied Materials, Bangalore. &emsp;&emsp;&emsp;
                        <Image src={Materials} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>M/s. Turning Point, Bangalore. &emsp;&emsp;&emsp;
                        <Image src={Turning} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>M/s. Airvana Networks India PVT LTD, Bangalore. &emsp;&emsp;&emsp;
                        <Image src={Airvana} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <td>M/S Electro mag Devices Pvt Ltd , MG Road. &emsp;&emsp;&emsp;
                          <Image src={Electro} alt="" className="img-fluid"></Image>
                        </td>
                      </tr>
                      <tr>
                        <td>17</td>
                        <td>M/S Electro mag Devices Pvt Ltd, Bangalore.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr className="text-danger"></hr>
              <div className="py-md-3">
                <table className="table-bordered table">
                  <thead className="text-center bordered text-uppercase bg-primary text-white">
                    <th colspan="2">List Of Turnkey Contractors</th>
                  </thead>
                  <thead className="">
                    <th>SL.NO</th>
                    <th>Description</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>M/s Apple Interiors, Bangalore</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        M/S. Parul Associates Interiors Pvt Ltd, Bangalore &emsp;&emsp;&emsp;
                        <Image src={Parul} alt="" className="img-fluid"></Image>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>M/s East Distribution, Bangalore</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M/S Bhagavathi Infra Solution Ltd, Bangalore</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M/S Sharadi Associates, Bangalore</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>M/s Admire Architects Pvt Ltd, Bangalore</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>M/s Spaciozone Design India Pvt Ltd, Bangalore</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="text-danger"></hr>
              <div className="py-md-3">
                <table className="table-bordered table">
                  <thead className="text-center bordered text-uppercase bg-primary text-white">
                    <th colspan="3">
                      List Of Major Projects Handled Our Previous Tenure At Out
                      Of Station
                    </th>
                  </thead>
                  <thead className="text-uppercase">
                    <th>SL.NO</th>
                    <th>Description</th>
                    <th>Type Of Projects</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>M/s Suzion Energy Ltd, Secunderbad</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>M/S. Appollo Health and Lifestyle Ltd, Hyderabad</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>M/s Vista Pharmaceuticals, Nalgonda</td>
                      <td>
                        Internal Electrification Including UPS Dedicate Earth
                        Station and Copper Strip
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M/S Viteos Capital Market Service Ltd, Mumbai</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M/S Monsanto India Ltd, Hyderabad</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>
                        M/s Honeywell Technology Solutions Lab Pvt Ltd,
                        Hyderabad
                      </td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>M/s Sri Siddhartha Dental College, Tumkur</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>
                        M/s Sri Siddhartha Institute of Technology, Tumkur
                      </td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="text-danger"></hr>
              <div className="py-md-3">
                <table className="table-bordered table">
                  <thead className="text-center bordered text-uppercase bg-primary text-white  ">
                    <th colspan="3">
                      List Of Major Projects Handled Our Previous Tenure
                    </th>
                  </thead>
                  <thead className="text-uppercase">
                    <th>SL.NO</th>
                    <th>Description</th>
                    <th>Type Of Projects</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        M/s Honeywell Technology Solution Lab Pvt Ltd,
                        Bannerghatta Road, Bangalore
                      </td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>
                        M/s Honeywell Technology Solution Lab Pvt Ltd,
                        Devarabisanahalli, Bangalore
                      </td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>M/s Silcarb Heating Elements Pvt Ltd, Bangalore</td>
                      <td>
                        Internal Electrification Including UPS Dedicate Earth
                        Station and Copper Strip
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>M/S Crowne Plaza, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>M/S Malathi Manipal Hospital, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>M/s Motorola India Pvt Ltd, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>M/s Manhattan Associates, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>M/s Velankani Software Pvt Ltd, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>9</td>
                      <td>M/s Prestige Blue Chip Software Park, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                    <tr>
                      <td>10</td>
                      <td>M/s Aravind Lifstyle Brands Ltd, Bangalore</td>
                      <td>
                        Internal Electrification Including Lighting and Power
                        Wiring for Workstation
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div id="project-img" className="py-3 py-md-4">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="">
                <h3 className="pb-4">HCL-ITPL-BLR</h3>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image1} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image2} alt="" className="img-fluid"></Image>
             </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image3} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image4} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image5} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image6} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image7} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image8} alt="" className="img-fluid"></Image>
             </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="project-img" className="py-3 py-md-4">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="">
                <h3 className="pb-4">HCL-Jigani</h3>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image11} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image12} alt="" className="img-fluid"></Image>
             </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image13} alt="" className="img-fluid"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="project-img" className="py-3 py-md-4">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="">
                <h3 className="pb-4">ITPL-Aviator-7F</h3>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image21} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image22} alt="" className="img-fluid"></Image>
             </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image23} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image24} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image25} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image26} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image27} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image28} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image29} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image30} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image31} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image32} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image33} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image34} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image35} alt="" className="img-fluid"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="project-img" className="py-3 py-md-4">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="">
                <h3 className="pb-4">Schneider-Jigani</h3>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image41} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image42} alt="" className="img-fluid"></Image>
             </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image43} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image44} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image45} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image46} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image47} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image48} alt="" className="img-fluid"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="project-img" className="py-3 py-md-4">
        <Container>
          <Row>
            <div className="col-md-12">
              <div className="">
                <h3 className="pb-4">VijaylakshmiSilks-Richmond Road</h3>
              </div>
            </div>
          </Row>
          <Row className="g-3">
            <Col md={3} xs={6}>
              <div className="text-center">
              <Image src={Image51} alt="" className="img-fluid"></Image>
              </div>
            </Col>
            <Col md={3} xs={6}>
             <div className="text-center">
             <Image src={Image52} alt="" className="img-fluid"></Image>
             </div>
            </Col>
          </Row>
        </Container>
      </div>


    </div>
  );
};

export default Projects;
