import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Image1 from "../Images/slider1/img-1.png";
import Image2 from "../Images/slider1/img-2.png";
import Image3 from "../Images/slider1/img-3.png";
import Image4 from "../Images/slider1/img-4.png";
import Image11 from "../Images/client-logo/c-1.png";
import Image12 from "../Images/client-logo/c-2.png";
import Image13 from "../Images/client-logo/c-3.png";
import Image14 from "../Images/client-logo/c-4.png";
import Image15 from "../Images/client-logo/c-5.png";
import Image16 from "../Images/client-logo/c-6.png";
import Image17 from "../Images/client-logo/c-7.png";
import Image18 from "../Images/client-logo/c-8.png";
import Image19 from "../Images/client-logo/c-9.png";
import Image20 from "../Images/client-logo/c-10.png";
import Image21 from "../Images/client-logo/c-11.png";
import Image22 from "../Images/client-logo/c-12.png";
import Image23 from "../Images/client-logo/c-13.png";
import Image24 from "../Images/client-logo/c-14.png";
import Image25 from "../Images/client-logo/c-15.png";
import Image26 from "../Images/client-logo/c-16.png";
import Image27 from "../Images/client-logo/c-17.png";
import Image28 from "../Images/client-logo/c-18.png";
import Image29 from "../Images/client-logo/c-19.png";
import Image30 from "../Images/client-logo/c-20.png";
import Image31 from "../Images/client-logo/c-21.png";
import Image32 from "../Images/client-logo/c-22.png";
import Image33 from "../Images/client-logo/c-23.png";
import Image34 from "../Images/client-logo/c-24.png";
import Image35 from "../Images/client-logo/c-25.png";
import Image36 from "../Images/client-logo/c-26.png";
import Image37 from "../Images/client-logo/c-27.png";
import Image38 from "../Images/client-logo/c-28.png";
import Image39 from "../Images/client-logo/c-29.png";
import Image40 from "../Images/client-logo/c-30.png";
import Image41 from "../Images/client-logo/c-31.png";
import Image42 from "../Images/client-logo/c-32.png";
import Image43 from "../Images/client-logo/c-33.png";
import Image44 from "../Images/client-logo/c-34.png";
import Image45 from "../Images/client-logo/c-35.png";
import Image46 from "../Images/client-logo/c-36.png";
import Image47 from "../Images/client-logo/c-37.png";
import Image48 from "../Images/client-logo/c-38.png";
import Image49 from "../Images/client-logo/c-39.png";
import Image50 from "../Images/client-logo/c-40.png";
import Image51 from "../Images/client-logo/c-41.png";
import Image52 from "../Images/client-logo/c-42.png";
import Image53 from "../Images/client-logo/c-43.png";
import Image54 from "../Images/client-logo/c-44.png";
import Image55 from "../Images/client-logo/c-45.png";
import Image56 from "../Images/client-logo/c-46.png";
// import Image57 from "../Images/client-logo/c-47.png";
// import Image58 from "../Images/client-logo/c-47.png";

const Gallery = () => {
  // const images = [
  //   { id: 1, imgName: Image1, alt: "", tag: "1" },
  //   { id: 2, imgName: Image2, alt: "", tag: "2" },
  //   { id: 3, imgName: Image3, alt: "", tag: "1" },
  //   { id: 4, imgName: Image4, alt: "", tag: "2" },
  // ];

  return (
    <>
      {/* <div id="top-page" className="py-3 py-md-4 bg-primary text-white">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center ">
                <h1 className="text-white">
                  Clients
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="gallery" className="pt-3 pb-3 pt-md-5 pb-md-5 bg-light">
        <Container>
          <SimpleReactLightbox>
            <SRLWrapper>
              <Row className="g-3">
                {images.map((item) => (
                  <Col md={3} xs={6} key={item.id}>
                    <div className="m-1 text-center border rounded">
                      <a href={item.imgName}>
                        <Image
                          src={item.imgName}
                          alt={item.alt}
                          className="img-fluid"
                        />
                      </a>
                    </div>
                  </Col>
                ))}
              </Row>
            </SRLWrapper>
          </SimpleReactLightbox>
        </Container>
      </div> */}


     <div id="top-page" className="py-3 py-md-4 bg-primary text-white">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="text-center ">
                <h1 className="text-white">
                  Clients
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="client-logo" className="py-3 py-md-5">
        <Container>
          <Row className="g-3">
            <Col md={3} xs={6}>
            <Image src={Image11} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6}>
            <Image src={Image12} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6}>
            <Image src={Image13} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6}>
            <Image src={Image14} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image15} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image16} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image17} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image18} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image19} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image20} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image21} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image22} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image23} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image24} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image25} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image26} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
             <Col md={3} xs={6} className="">
            <Image src={Image27} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image28} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image29} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image30} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image31} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image32} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image33} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="my-4">
            <Image src={Image34} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image35} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image36} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
             <Col md={3} xs={6} className="">
            <Image src={Image37} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image38} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image39} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image40} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image41} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image42} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image43} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image44} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image45} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image46} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image47} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image48} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image49} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image50} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image51} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image52} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image53} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image54} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image55} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            <Col md={3} xs={6} className="">
            <Image src={Image56} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>
            {/* <Col md={3} xs={6} className="">
            <Image src={Image57} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col> */}
            {/* <Col md={3} xs={6} className="">
            <Image src={Image58} alt="" className="img-fluid border border-1 p-5"></Image>
            </Col>   */}


          </Row>
        </Container>
      </div>
      
    </>
  );
};

export default Gallery;
